import API from "@/common/axios";
import moment from "moment";
// data.operationType

/**
 * 记录操作日志的函数
 * 
 * @param {Object} data - 操作日志的数据对象
 * @param {number} data.operationType - 操作类型，数字编码表示不同的操作
 *                                      1. 查看用户信息；
 *                                      2. 查看订单管理；
 *                                      3. 查看客户收藏；
 *                                      4. 查看津贴账户；
 *                                      5. 查看单品会话；
 *                                      6. 点击无需回复；
 *                                      7. 查看朋友圈；
 *                                      8. 查看小程序卡片；
 *                                      9. 查看SOP消息；
 *                                      10. 点击代下单按钮；
 *                                      11. 左键点击用户发送的小程序卡片；
 *                                      12. 聊天窗口发送按钮；
 *                                      13. 点击发票信息的编辑icon
 *                                      14. 点击发票信息弹窗的确认按钮
 *                                      15. 点击订单备注输入框
 *                                      16. 选中当前商品可用的任意福利
 *                                      17. 点击使用用户账户资产下单按钮
 *                                      18. 点击发放福利下单按钮
 *                                      
 * @param {string} data.extraData - 附加数据，与操作相关的额外信息，格式为json字符串
 * 
 * @returns {void}
 */
export const Trace = function (data) {
  if (!data) {
    console.error('未传入参数');
    return;
  }
  if (typeof data != 'object') {
    console.error('参数类型不正确');
    return;
  }
  data.time = moment().format('YYYY-MM-DD HH:mm:ss');
  let accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}');
  data.employeeId = accountInfo.employee_id || "";
  data.staffName = accountInfo.nickname || "";
  let { externalUserid, userName, xbjId, xbjNickName, unionId } = Trace.selectUserInfo;
  data.externalUserId = externalUserid || "";
  data.nickname = userName || "";
  data.xbjId = xbjId || "";
  data.xbjNickname = xbjNickName || "";
  data.unionId = unionId || "";
  API({
    url: "/customer/saveOperationLog.do",
    data
  });
};

Trace.selectUserInfo = {};

export default Trace;